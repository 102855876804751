import { Component, Input, OnInit} from "@angular/core";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BasePage } from "src/app/pages/base-page/base-page";
import { Faq } from "../../models/faq";

@Component({
  selector: 'app-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.scss']
})
export class FaqDetailComponent extends BasePage implements OnInit {
  @Input() faq: Faq;
  @Input() index: number;

  editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: []
    }
  }

  constructor(
  ) {
    super();
  }

}
